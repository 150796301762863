import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, Icon } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import {
  AuthProvider,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth';

import ButtonComponent from '@/components/utility/microcomponents/Button';
import { AllowedUnverifiedEmails } from '@/constants/Emails';
import useAccountContext from '@/hooks/context/useAccountContext';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';
import { OnboardingForm } from '@/models/Onboarding';
import { initToken } from '@/utility/api';

import { authErrors } from '../../constants/FirebaseErrors';
import { auth } from '../../firebaseSetup';

const LoginOnboardingPage = ({ isSignUp, formDetails }: { isSignUp?: boolean; formDetails?: OnboardingForm }) => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();
  const { setOnboardingStep, onboardingStep, setOnboardingDirection } = useOnboardingContext();

  const { dispatchFirebaseAccountId } = useAccountContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const isLogin = isSignUp ? false : true;
  const { dispatchSnackbar } = useSnackbarContext();

  const { setValue, watch } = useFormContext();
  const formEmail = watch('email');

  const [password, setPassword] = useState<string>('');
  const [authProvider, setAuthProvider] = useState<AuthProvider>();
  const [authCredential, setAuthCredential] = useState<OAuthCredential>();

  const [signInAgainButton, setSignInAgainButton] = useState<boolean>(false);

  const errors = authErrors;

  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const appleProvider = new OAuthProvider('apple.com');

  const handleSignInSuccess = async (result: UserCredential) => {
    const user = result.user;
    const tokenResult = await auth.currentUser?.getIdTokenResult();
    if (!tokenResult) {
      return;
    }
    initToken(tokenResult.token);
    localStorage.setItem('onboardingForm', JSON.stringify(formDetails));

    if (user && !user.emailVerified && AllowedUnverifiedEmails.includes(user.email || '') === false) {
      navigate('/email-verification');
      return;
    }

    if (user && isSignUp) {
      setValue('email', user.email);
      return setOnboardingStep(OnboardingSteps.CREATE_ACCOUNT);
    }

    if (user && !isSignUp) {
      await dispatchFirebaseAccountId(user.uid);
      navigate('/home');
    }
  };

  const handleSignInError = (error: FirebaseError) => {
    if (error.code === 'auth/account-exists-with-different-credential') {
      const credential = OAuthProvider.credentialFromError(error);
      if (credential) {
        setAuthCredential(credential);
        const email = error.customData?.email as string;
        fetchSignInMethodsForEmail(auth, email).then(() => {
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            login_hint: email,
          });
          setAuthProvider(provider);
          setSignInAgainButton(true);
        });
      }
    } else if (error.code) {
      processError(error);
    }

    setIsLoading(false);
  };

  const popUpSignIn = () => {
    if (authProvider && authCredential) {
      signInWithPopup(auth, authProvider).then(async (resp) => {
        await linkWithCredential(resp.user, authCredential);
        handleSignInSuccess(resp);
      });
    }
  };

  const passwordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const toggleLogin = () => {
    const goToSignUp = () => {
      setOnboardingDirection && setOnboardingDirection('back');
      setOnboardingStep(OnboardingSteps.PRE_SCREEN);
    };

    isLogin ? goToSignUp() : navigate('/login');
  };

  const signIn = () => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, formEmail, password)
      .then(async (resp) => {
        if (!resp.user.emailVerified && AllowedUnverifiedEmails.includes(resp.user.email || '') === false) {
          sendEmailVerification(resp.user, { url: window.location.origin });
          navigate('/email-verification');
          return;
        }
        handleSignInSuccess(resp);
        setIsLoading(false);
      })
      .catch((err) => {
        processError(err);
        setIsLoading(false);
      });
  };

  const signInWithGoogle = () => {
    setIsLoading(true);
    signInWithPopup(auth, googleProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const signInWithFacebook = () => {
    setIsLoading(true);
    signInWithPopup(auth, facebookProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const signInWithApple = () => {
    setIsLoading(true);
    signInWithPopup(auth, appleProvider)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
      });
  };

  const createNewUser = () => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, formEmail, password)
      .then(async (resp) => {
        await handleSignInSuccess(resp);
      })
      .catch((err) => {
        handleSignInError(err);
        setIsLoading(false);
      });
  };

  const processError = (error: FirebaseError) => {
    const code = error.code?.replace('auth/', '');
    dispatchSnackbar({
      type: 'OPEN_SNACKBAR',
      payload: {
        message: errors[code],
        type: 'error',
      },
    });
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const forgotPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    if (!formEmail) {
      dispatchSnackbar({
        type: 'OPEN_SNACKBAR',
        payload: {
          message: 'Please enter an email address',
          type: 'error',
        },
      });
      return;
    }
    sendPasswordResetEmail(auth, formEmail)
      .then(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Password reset email sent, this can take a few minutes to arrive',
            type: 'success',
          },
        });
      })
      .catch(() => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: 'Please enter a valid email address',
            type: 'error',
          },
        });
      });
  };

  return (
    <div className="login-page" data-testid="login-page">
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingDirection && setOnboardingDirection('back');
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      <h2 className="mt40 mb48">
        {isSignUp ? t('ONBOARDING.LAST-STEP-CREATE-YOUR-ACCOUNT') : t('LOGIN-PAGE.WELCOME-BACK')}
      </h2>
      <div className="container">
        <div className="form-container ml-auto mr-auto">
          <form>
            <label>
              <p className="mt32">{t('COMMON.EMAIL')}</p>
              <input
                placeholder={t('COMMON.ENTER-EMAIL-ADDRESS')}
                name="email"
                data-testid="email-input"
                value={formEmail}
                disabled={isLoading}
                onChange={(e) => setValue('email', e.target.value)}
              />
            </label>
            <label>
              <div className="d-flex mt16 jc-space-between">
                <p>{t('COMMON.PASSWORD')}</p>
                {isLogin && (
                  <p className="text-blue-gradient cursor-pointer" onClick={forgotPassword}>
                    {t('COMMON.FORGOT-PASSWORD')}
                  </p>
                )}
              </div>
              <input
                placeholder={t('COMMON.ENTER-PASSWORD')}
                type={visible ? 'text' : 'password'}
                autoComplete="on"
                data-testid="password-input"
                disabled={isLoading}
                value={password}
                onChange={passwordChange}
              />
              <Button className="icon-btn input-visibility-icon" onClick={toggleVisibility}>
                {visible ? <Icon className="">visibility_off</Icon> : <Icon className="">visibility</Icon>}
              </Button>
            </label>
            {isLogin && (
              <Button
                className="btn-white ml0 w100p mt-48"
                onClick={signIn}
                disabled={isLoading || !formEmail || !password}
                data-testid="login-button"
                type="submit"
              >
                {t('COMMON.LOGIN')}
              </Button>
            )}
            {!isLogin && (
              <Button
                className="btn-white ml0 w100p mt-48"
                data-testid="sign-up-button"
                onClick={createNewUser}
                disabled={isLoading || !formEmail || !password}
                type="submit"
              >
                {t('COMMON.SIGN-UP')}
              </Button>
            )}
          </form>

          <div className="d-flex form-divider">
            <div className="line"></div>
            <p className="small ml8 mr8">{t('COMMON.OR')}</p>
            <div className="line"></div>
          </div>
          <div className="d-flex gap16 jc-center mt16">
            <Button className="btn-white icon-btn" disabled={isLoading} onClick={signInWithGoogle}>
              <img src="/images/logos/google-logo.svg" alt="" />
            </Button>
            <Button className="btn-white icon-btn" disabled={isLoading} onClick={signInWithFacebook}>
              <img src="/images/logos/facebook-logo.svg" alt="" />
            </Button>
            <Button className="btn-white icon-btn" disabled={isLoading} onClick={signInWithApple}>
              <img src="/images/logos/apple-logo.svg" alt="" />
            </Button>
          </div>
          <Dialog open={signInAgainButton}>
            <div className="min-w400 max-w400">
              <h5>{t('LOGIN-PAGE.ACCOUNT-EXISTS-WITH-DIFFERENT-CREDENTIAL-DIALOG')}</h5>
              <div className="d-flex">
                <Button
                  className="ml0 w100p mt16"
                  disabled={isLoading}
                  onClick={() => {
                    setSignInAgainButton(false);
                  }}
                >
                  {t('COMMON.CLOSE')}
                </Button>
                <Button className="btn-white ml0 w100p mt16" disabled={isLoading} onClick={popUpSignIn}>
                  {t('COMMON.CONTINUE')}
                </Button>
              </div>
            </div>
          </Dialog>

          <p className="text-center small mt48 text-faded">
            {isLogin ? t('LOGIN-PAGE.DONT-HAVE-ACCOUNT') : t('LOGIN-PAGE.ALREADY-HAVE-ACCOUNT')}{' '}
            <ButtonComponent isCustom onClick={toggleLogin}>
              <span className="cursor-pointer text-white fw-bold">
                {isLogin ? t('COMMON.SIGN-UP') : t('COMMON.LOGIN')}
              </span>
            </ButtonComponent>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginOnboardingPage;
