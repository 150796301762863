import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, CircularProgress, Icon, Modal } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AxiosResponse } from 'axios';
import { addDays, addWeeks, format, isBefore, parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';

import useAccountContext from '@/hooks/context/useAccountContext';
import useOnboardingContext from '@/hooks/context/useOnboardingContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { OnboardingSteps } from '@/models/Enums';
import { ReleaseCycleModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import Card from '../utility/microcomponents/Card';

const ReleaseDatePage = ({
  isOnboarding,
  closeModal,
}: {
  isOnboarding?: boolean;
  closeModal?: (isRefetch?: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { breakpointHit } = useBreakpoints();

  const navigate = useNavigate();
  const { dispatchSnackbar } = useSnackbarContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalHasBeenShown, setModalHasBeenShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { accountId } = useAccountContext();
  const location = useLocation();
  const { setValue, watch } = useFormContext();
  const releaseDate = watch('releaseDetails.releaseDate');
  const releaseName = watch('releaseDetails.releaseTitle');

  const [selectedDate, setSelectedDate] = useState<string>(
    releaseDate ? new Date(releaseDate).toISOString() : addWeeks(new Date(), 8).toISOString()
  );

  const { setOnboardingStep, onboardingStep, setOnboardingDirection } = useOnboardingContext();

  const createRelease = () => {
    if (!accountId) return;
    setIsLoading(true);
    ReleaseCyclesAPI.createReleaseCycle({
      data: { accountId: accountId, name: releaseName, releaseDate: format(new Date(releaseDate), 'yyyy-MM-dd') },
    })
      .then(async (resp: AxiosResponse<ReleaseCycleModel>) => {
        closeModal && closeModal(true);
        navigate(`/release-cycles?id=${resp.data.id}`);
        setIsLoading(false);
      })
      .catch((error) => {
        dispatchSnackbar({
          type: 'OPEN_SNACKBAR',
          payload: {
            message: error.response.data.errorMessage,
            type: 'error',
          },
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isBefore(new Date(selectedDate), addDays(addWeeks(new Date(), 8), -1)) && !modalHasBeenShown) {
      setIsModalOpen(true);
      setModalHasBeenShown(true);
    }
    setValue('releaseDetails.releaseDate', selectedDate);
  }, [modalHasBeenShown, selectedDate, setValue]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="to-do-modal">
          <h5>{t('RELEASE-CYCLES.ARE-YOU-SURE-ABOUT-THIS-RELEASE-DATE')}</h5>
          <p className="text-faded mt8">{t('RELEASE-CYCLES.ARE-YOU-SURE-ABOUT-THIS-RELEASE-DATE-DESCRIPTION')}</p>
          <Button
            className="btn-white mt20 ml0 w100p"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            I'm sure
          </Button>
        </div>
      </Modal>
      {breakpointHit && (
        <div className="text-left ml-16">
          <Button
            className="no-bg text-lef m0 pl0"
            onClick={() => {
              setOnboardingDirection && setOnboardingDirection('back');
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            <Icon>chevron_left</Icon>
            {t('COMMON.BACK')}
          </Button>
        </div>
      )}
      <h2 className={`text-center ${breakpointHit ? 'mt10' : 'mt40'} mb48 max-w1000 mr-auto ml-auto`}>
        {t('ONBOARDING.SELECT-A-DATE')}
      </h2>
      <p className="mt16 text-faded mb48">{t('ONBOARDING.SELECT-A-DATE-DESCRIPTION')}</p>
      <div className="max-w600 ml-auto mr-auto mt20 mb300" data-testid="release-date-input">
        <Card className="w-fit ml-auto mr-auto p0 pb10">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
            <StaticDatePicker
              orientation="portrait"
              displayStaticWrapperAs="desktop"
              slotProps={{}}
              views={['day']}
              minDate={addDays(new Date(), 0)}
              value={parseISO(selectedDate)}
              onChange={(value) => {
                if (value) {
                  setSelectedDate(value.toISOString());
                }
              }}
            />
          </LocalizationProvider>
        </Card>
      </div>
      {/* <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          <Button
            className="border-btn min-w130"
            onClick={() => {
              setOnboardingStep(onboardingStep - 1);
            }}
          >
            {t('COMMON.BACK')}
          </Button>
          <Button
            className="btn-white min-w130"
            data-testid="continue-button"
            onClick={() => {
              if (isOnboarding) {
                if (location.pathname === '/onboarding') {
                  return setOnboardingStep(OnboardingSteps.CREATE_ACCOUNT);
                }
                setOnboardingStep(OnboardingSteps.SIGN_UP);
              } else {
                createRelease();
              }
            }}
          >
            {isLoading ? <CircularProgress size={16} /> : t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div> */}
      <div className="onboarding-footer">
        <div className="d-flex jc-end mt40">
          {!breakpointHit && (
            <Button
              className="border-btn min-w130"
              onClick={() => {
                setOnboardingDirection && setOnboardingDirection('back');
                setOnboardingStep(onboardingStep - 1);
              }}
            >
              {t('COMMON.BACK')}
            </Button>
          )}
          <Button
            className={`btn-white min-w130 ${breakpointHit ? 'w100p' : ''}`}
            data-testid="continue-button"
            onClick={() => {
              if (isOnboarding) {
                setOnboardingDirection && setOnboardingDirection('next');

                if (location.pathname === '/onboarding') {
                  return setOnboardingStep(OnboardingSteps.CREATE_ACCOUNT);
                }
                setOnboardingStep(OnboardingSteps.SIGN_UP);
              } else {
                createRelease();
              }
            }}
          >
            {isLoading ? <CircularProgress size={16} /> : t('COMMON.CONTINUE')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ReleaseDatePage;
